import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-erreurvalidation',
  templateUrl: './erreurvalidation.component.html',
  styleUrls: ['./erreurvalidation.component.scss'],
})
export class ErreurvalidationComponent implements OnInit {

  texte:string;

  constructor(private popoverController: PopoverController,private navParams: NavParams) { }

	ngOnInit() {this.texte = this.navParams.data.texte;}

	close(){this.popoverController.dismiss();}

}
