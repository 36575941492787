import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit {

  partenaire:any;

  constructor(private popoverController: PopoverController,private router: Router,private navParams: NavParams) { }

  ngOnInit() {
  	this.partenaire = this.navParams.data.partenaire;
  	this.partenaire = JSON.parse(this.partenaire);
  	//console.log("ValidationComponent partenaire : ");console.log(this.partenaire);
  }
  close(){
  	this.popoverController.dismiss();
    //this.router.navigate(['/toutelabreizh/touslespartenaires']);
  	this.router.navigate(['/partenaire',{ partenaire: JSON.stringify(this.partenaire),historique: "validation"}]);
  }
}
