import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthentificationService } from '../services/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthentificationService,
    private router: Router
  ){}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  	
  	let userAuthenticated = false; // Get the current authentication state from a Service!
    userAuthenticated = this.authService.isLoggedIn;
    console.log("userAuthenticated : ");console.log(userAuthenticated);
		if (userAuthenticated) {
			return true;
		} else {
      //this.router.navigate(['login']);return false;
      return true;
		}
  }
  
}
