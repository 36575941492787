import { NgModule } from '@angular/core';
import { PreloadAllModules,CanActivate, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {path: '',redirectTo: 'login',pathMatch: 'full'},
  //{path: 'autourdemoi',loadChildren: () => import('./pages/autourdemoi/autourdemoi.module').then( m => m.AutourdemoiPageModule)},
  {path: 'login',loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)},
  {path: 'toutelabreizh',canActivate: [AuthGuard],loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)},
  {
    path: 'favoris',
    loadChildren: () => import('./pages/favoris/favoris.module').then( m => m.FavorisPageModule)
  },
  {
    path: 'explorer',
    loadChildren: () => import('./pages/explorer/explorer.module').then( m => m.ExplorerPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'partenaire',
    loadChildren: () => import('./pages/partenaire/partenaire.module').then( m => m.PartenairePageModule)
  },
  {
    path: 'explorerliste',
    loadChildren: () => import('./pages/explorerliste/explorerliste.module').then( m => m.ExplorerlistePageModule)
  },
  {
    path: 'touslespartenaires',
    loadChildren: () => import('./pages/touslespartenaires/touslespartenaires.module').then( m => m.TouslespartenairesPageModule)
  },
  {
    path: 'inscription',
    loadChildren: () => import('./pages/inscription/inscription.module').then( m => m.InscriptionPageModule)
  },
  {
    path: 'resetpwd',
    loadChildren: () => import('./pages/resetpwd/resetpwd.module').then( m => m.ResetpwdPageModule)
  },
  {
    path: 'selectioncodetoutela',
    loadChildren: () => import('./pages/selectioncodetoutela/selectioncodetoutela.module').then( m => m.SelectioncodetoutelaPageModule)
  },
  {
    path: 'ajoutcodetoutela',
    loadChildren: () => import('./pages/ajoutcodetoutela/ajoutcodetoutela.module').then( m => m.AjoutcodetoutelaPageModule)
  },
  {
    path: 'validation',
    loadChildren: () => import('./pages/validation/validation.module').then( m => m.ValidationPageModule)
  },
  {
    path: 'apropos',
    loadChildren: () => import('./pages/apropos/apropos.module').then( m => m.AproposPageModule)
  },
  {
    path: 'rgpd',
    loadChildren: () => import('./pages/apropos/rgpd.module').then( m => m.RgpdPageModule)
  },
  {
    path: 'abonnement',
    loadChildren: () => import('./pages/abonnement/abonnement.module').then( m => m.AbonnementPageModule)
  },
  {
    path: 'offresweb',
    loadChildren: () => import('./pages/offresweb/offresweb.module').then( m => m.OffreswebPageModule)
  },
  {
    path: 'demarrage',
    loadChildren: () => import('./pages/installation/installation.module').then( m => m.InstallationPageModule)
  },
  //{path: 'nouveautes',loadChildren: () => import('./pages/nouveautes/nouveautes.module').then( m => m.NouveautesPageModule)},
  //{path: 'notifications',loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
