import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HistoriqueService } from './services/historique.service';
import { AuthentificationService } from './services/authentification.service';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Storage } from '@ionic/storage';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})


export class AppComponent implements OnInit {

  slideInstallation = false;
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Autour de moi',
      //url: '/pages/AutourdemoiPage',
      url : "autourdemoi",
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public historiqueService: HistoriqueService,
    public authService: AuthentificationService,
    private router: Router,
    private location: Location,
    private storage: Storage,
    private geolocation: Geolocation,
    private androidPermissions: AndroidPermissions
  ) {
    this.geolocalisation();
    this.initializeApp();
    this.backButtonEvent();
    this.historiqueService.loadRouting();
  }



  backButtonEvent() {
    if(this.platform.is('android')){
      this.platform.backButton.subscribeWithPriority(9999,()=>{
        //technique 1 : historiqueService
        //let previousPage = this.historiqueService.getPreviousUrl();        
        //avant de redirigier supprimer la derniere page de lhistorique pour ne pas être redirigier a linfini
        //this.router.navigate([previousPage]);
        
        //technique 2 : previousPage        
        this.location.back();
      });
    }
    /*this.platform.backButton.subscribe(async () => {
      console.log("backButton : ne rien faire");
    });*/
  }


  geolocalisation(){
    console.log("app geolocalisation");
    this.geolocation.getCurrentPosition().then((resp) => {
     this.storage.set('latitude', resp.coords.latitude);this.storage.set('longitude', resp.coords.longitude);
     console.log("init geoloc : ");
     console.log(resp.coords.latitude);console.log(resp.coords.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }


  initializeApp() {
    this.platform.ready().then(() => {


      /*this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => alert('Has permission?'+result.hasPermission),
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
      );
      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION, this.androidPermissions.PERMISSION.GET_ACCOUNTS]);
      */

      //this.router.navigate(['/demarrage']);//TEST REDIRECTION
      this.storage.get('slideInstallation').then((slideInstallation) => {
        if(slideInstallation){
          this.slideInstallation = slideInstallation;
          this.storage.set('slideInstallation', true);
          this.router.navigate(['/demarrage'])
        }        
      },error => {console.log(error);});


      this.statusBar.styleDefault();
      //this.splashScreen.hide();
      this.authService.getToken();

      //A2HS
      //console.log("ADD TO HOME SCREEN");
      /*let deferredPrompt;
      deferredPrompt.prompt();
      if (deferredPrompt) {
        deferredPrompt.prompt();
      }*/

    });


    


    
  }

  ngOnInit() {
    this.geolocalisation();
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
