import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from "rxjs/operators";
import { NavigationEnd } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HistoriqueService {

  private history = [];

  constructor(private router: Router) { }

  public loadRouting(): void {
  	console.log("HistoriqueService : loadRouting");
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
      	//console.log("loadRouting change event : ");console.log(event);
        this.history = [...this.history, urlAfterRedirects];
        //console.log(this.history);
      });

  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }


}
