import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ajoutcode',
  templateUrl: './ajoutcode.component.html',
  styleUrls: ['./ajoutcode.component.scss'],
})
export class AjoutcodeComponent implements OnInit {

  partenaire:any;

  constructor(private popoverController: PopoverController,private router: Router,private navParams: NavParams) { }

  ngOnInit() {
  	//this.partenaire = this.navParams.data.partenaire;
  	//this.partenaire = JSON.parse(this.partenaire);
  	//console.log("ValidationComponent partenaire : ");console.log(this.partenaire);
  }
  close(){
  	this.popoverController.dismiss();
    this.router.navigate(['/toutelabreizh/touslespartenaires']);
  }
}
