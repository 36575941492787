import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss'],
})
export class GeolocationComponent implements OnInit {

  texte:string;

  constructor(private popoverController: PopoverController,private navParams: NavParams) { }

	ngOnInit() {this.texte = this.navParams.data.texte;}

	close(){this.popoverController.dismiss();}

}
