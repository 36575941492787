import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HTTP } from '@ionic-native/http/ngx';
import { Storage } from '@ionic/storage';



@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  isLoggedIn = false;
  token:any;

  constructor(
    private http: HTTP,
    private storage: Storage,
  ){ }



  testBO(){
    return new Promise((resolve, reject) => {
      let credentials_test = {username: "jonathan", password: 'jicom' };
      this.http.post(environment.API_TOUTELA+'testlogin', credentials_test,{})
        .then(data => {resolve(data);
        }).catch(error => {reject(error);});
    });
  }
  testJicomite(){
    return new Promise((resolve, reject) => {
      let credentials_test = {username: "jonathan", password: 'jicom' };
      this.http.post('http://lechequelocal.fr/api/login', credentials_test,{})
        .then(data => {resolve(data);
        }).catch(error => {reject(error);});
    });
  }
  testValidationJicomite(code_toutela,parteraire_id,deal_id,type){
    return new Promise((resolve, reject) => {      
      this.http.post(environment.API_TOUTELA+'jicom-valider-offre',{'code_toutela':code_toutela,'partenaire_id': parteraire_id, 'deal_id': deal_id, 'type': type},{})
        .then(data => {resolve(data);
        }).catch(error => {reject(error);});   
    });
  }
  testValidationBo(deal,store,stampingDate,type){
    return new Promise((resolve, reject) => {
      //this.storage.get('token').then((token) => {
        this.http.setHeader('*',"Accept", "application/json");
        //this.http.setHeader('*','Authorization', 'Bearer ' + token);
        let param_post = {deal: deal, store: store,stampingDate: stampingDate,type: type };
        this.http.post(environment.API_TOUTELA+'stampActivities/', param_post, {})
          .then(res => {resolve(res);
          }).catch(error => {reject(error);});
      //});
    });
  }








  resetPassword(email){
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.http.setHeader('*',"Accept", "application/json");this.http.setHeader('*',"Content-Type", "application/json");
        this.http.setDataSerializer('json');this.http.setHeader('*','Authorization', 'Bearer ' + token);
        let param = { login: email };
        this.http.post(environment.API_TOUTELA+'resetPassword', param, {})
        .then(res => {
          resolve(res);//console.log(res);
        })
        .catch(error => {
          console.log(error.error);reject(error);
        });
      });
    });
  }

  updatePassword(old_password,new_password){
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.http.setHeader('*',"Accept", "application/json");this.http.setHeader('*',"Content-Type", "application/json");
        this.http.setHeader('*','Authorization', 'Bearer ' + token);
        this.http.setDataSerializer('json');
        let param = {_method: "PUT", old_password: old_password,new_password: new_password };
        this.http.post(environment.API_TOUTELA+'updatePassword', param, {})
        .then(res => {
          resolve(res);//console.log(res);
        })
        .catch(error => {
          console.log(error.error);reject(error);
        });
      });
    });
  }

  update(email,nom,prenom){
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.http.setHeader('*',"Accept", "application/json");
        this.http.setHeader('*',"Content-Type", "application/json");
        this.http.setHeader('*','Authorization', 'Bearer ' + token);
        this.http.setDataSerializer('json');
        let param = {_method: "PUT", login: email,firstName: prenom,lastName: nom };
        this.http.post(environment.API_TOUTELA+'customers', param, {})
        .then(res => {
          resolve(res);//console.log(res);
        })
        .catch(error => {
          console.log(error.error);reject(error);
        });
      });
    });
  }

  




  login(credentials){
  	return new Promise((resolve, reject) => {
        this.http.setHeader('*',"Accept", "application/json");
        this.http.setHeader('*',"Content-Type", "application/json");this.http.setDataSerializer('json');
        let credentials_test = {login: "matthieu@toutela-breizh.fr", password: 'jicom' };
        console.log("URL login : "+environment.API_TOUTELA);
        //console.log("credentials : ");console.log(credentials);
        this.http.post(environment.API_TOUTELA+'login', credentials, {})
        .then(res => {
          //console.log(res);
          let jsonRes = JSON.parse(res.data);
          this.storage.set('token', jsonRes.token);this.isLoggedIn = true;this.token = jsonRes.token;
          resolve(res);
        })
        .catch(error => {
          console.log(error);
          console.log(error.error); // error message as string
          //console.log(error.headers);
          //alert(error.error);          
          reject(error);
        });

    });
  }


  getToken() {
    console.log("inside getToken");
    return this.storage.get('token').then(      
      data => {
        console.log(data);
        this.token = data;
        if(this.token != null) {
          this.isLoggedIn=true;
        } else {
          this.isLoggedIn=false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
      }
    );
  }



  logout(){
    return new Promise((resolve, reject) => {
      this.storage.remove("token");this.storage.remove("user_email");
      this.isLoggedIn = false;
      delete this.token;
      resolve("logout");
    });
  }

  getInfosUser(){
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.http.setHeader('*',"Accept", "application/json");
        this.http.setHeader('*','Authorization', 'Bearer ' + token);
        this.http.get(environment.API_TOUTELA+"customers", {}, {})
        .then(res => {
          console.log(res);
          resolve(res);
        }).catch(error => {reject(error);});
      });
    });
  }
  
}
