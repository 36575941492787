import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { HelpComponent } from './component/help/help.component';
import { ErreurvalidationComponent } from './component/erreurvalidation/erreurvalidation.component';
import { GeolocationComponent } from './component/geolocation/geolocation.component';
import { ValidationComponent } from './component/validation/validation.component';
import { AjoutcodeComponent } from './component/ajoutcode/ajoutcode.component';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent,HelpComponent,ValidationComponent,ErreurvalidationComponent,AjoutcodeComponent,GeolocationComponent],
  entryComponents: [
    HelpComponent,ValidationComponent,ErreurvalidationComponent,AjoutcodeComponent,GeolocationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    Geolocation,
    LocationAccuracy,
    InAppBrowser,
    HTTP,
    SplashScreen,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
